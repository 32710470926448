import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { withFirebase } from './../Firebase';
import moment from 'moment';
import * as ROUTES from './../../constants/routes';

class Notifications extends Component {

  constructor(props) {
    super(props);
    this.state = {
      messages : [],
      totalMsg : 0
    }
  }
  componentDidMount() {
    const userId = localStorage.getItem('id');
    if(userId!=="" && userId!==null){
        this.props.firebase.notificationsDB().where("toUsers", "array-contains", userId).where("isRead", "==", false).orderBy('addDate', 'desc').limit(5).get({ source: 'server' }).then((querySnapshot) => {
          let messages = [];
          querySnapshot.forEach((msg) => {
            messages.push({ ...msg.data(), id: msg.id });
          });
          this.setState({
            messages : messages,
            totalMsg : messages.length
          });
        });
    }
  }

  handleClick(id,e){
    e.preventDefault();
    const that = this;
    const msgs = this.state.messages;
    const userId = localStorage.getItem('id');

    msgs.forEach((msg) => {
      const toUsersArray = msg.toUsers;
      if(id===msg.caseId && toUsersArray.includes(userId)){
        const addDate = msg.addDate;
        const caseId = msg.caseId;
        const fromUser = msg.fromUser;
        const isRead = true;
        const jobStatus = msg.jobStatus;
        const message = msg.message;
        const subject = msg.subject;
        const toUsers = msg.toUsers;
        const updateRef = that.props.firebase.notificationsDB().doc(msg.id);
              updateRef.set({
                addDate,
                caseId,
                fromUser,
                isRead,
                jobStatus,
                message,
                subject,
                toUsers
              }).then((docRef) => {

              }).catch((error) => {

              });

      }
    });
     this.props.history.push(ROUTES.CASE_DISPLAY + id);
     //window.location.reload();
  }

  render() {
    const userId = localStorage.getItem('id');
    const msg_display = (userId==="" || userId===null) ? 'Sign In to view notifications' : 'No new notifications';
    return (
      <div>
            <List className="msgList">
              {   
                  this.state.messages.length>0
                  ? this.state.messages.map(doc => (
                      <React.Fragment key={doc.id}>
                        <ListItem 
                          button 
                          component="a" 
                          href={ROUTES.CASE_DISPLAY + doc.caseId}
                          onClick={this.handleClick.bind(this,doc.caseId)}
                        >
                          <ListItemText primary={doc.message} secondary={moment.unix(doc.addDate.seconds).format('MMM DD, YYYY hh:mm A')} />
                        </ListItem>
                      </React.Fragment>
                    ))
                  : msg_display
              }
            </List>
    </div>
    );
  }
}

export default withRouter(withFirebase(Notifications));