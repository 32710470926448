import React from 'react';

import { AuthUserContext, withAuthorization } from '../Session';
import { PasswordForgetForm } from '../PasswordForget';
import PasswordChangeForm from '../PasswordChange';
import EditProfileForm from '../Profile';
import "./account.css";

import { Paper, Container, Typography, Grid } from '@material-ui/core';

const AccountPage = () => (
  <AuthUserContext.Consumer>
    {authUser => (
      <div className="container">
      <div className="common-box">
        <Container component="main" maxWidth="lg">
          <Grid container spacing={2}>
            <Grid className="job-dec box-shadow" item md={4} xs={12}>
          		<div className="sign-box">
                <Paper>
                  <Typography component="h1" variant="h5" color="primary">
                    Edit Profile <span> {authUser.email}</span>
                  </Typography>
                  <EditProfileForm />
                  </Paper>
          		</div>
            </Grid>

            <Grid className="job-dec box-shadow" item md={4} xs={12}>
              <div className="sign-box">
                <Paper>
                  <Typography component="h1" variant="h5" color="primary">
                    Change Password
                  </Typography>
                  <PasswordChangeForm />
                  </Paper>
              </div>
            </Grid>

            <Grid className="job-dec box-shadow" item md={4} xs={12}>
              <div className="sign-box">
                <Paper>
                  <Typography component="h1" variant="h5" color="primary">
                    Reset Password
                  </Typography>
                  <PasswordForgetForm />
                  </Paper>
              </div>
            </Grid>
          </Grid>
        </Container>
        </div>
      </div>
    )}
  </AuthUserContext.Consumer>
);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(AccountPage);