import React, { Component} from 'react';
//import { Link } from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
//import { makeStyles } from '@material-ui/core/styles';
import {AuthUserContext} from '../Session';
import { Drawer, Toolbar, IconButton, Typography, Menu, MenuItem,Paper, Link } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { NotificationsNoneRounded } from '@material-ui/icons';
import Badge from '@material-ui/core/Badge';
import { ToastContainer } from 'react-toastify';
import { withFirebase } from './../Firebase';
import 'react-toastify/dist/ReactToastify.css';
import './navigation.css';
import { compose } from "recompose";
import Notifications from './notifications';


const mobileMenuId = 'primary-search-account-menu-mobile';
const ITEM_HEIGHT = 49;
class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl : null,
      setAnchorEl : null,
      mobileMoreAnchorEl : null,
      setMobileMoreAnchorEl : null,
      isMenuOpen: false,
      isMobileMenuOpen: false,
      totalMsg: 0,
	    activeClass: '',
      drawerIsOpen: false
    }
  }

  componentDidMount() {
    const userId = localStorage.getItem('id');
	
    if(userId!=="" && userId!==null){
        this.props.firebase.notificationsDB().where("toUsers", "array-contains", userId).where("isRead", "==", false).orderBy('addDate', 'desc').get({ source: 'server' }).then((querySnapshot) => {
          let messages = [];
          querySnapshot.forEach((msg) => {
            messages.push({ ...msg.data(), id: msg.id });
          });
          this.setState({
            totalMsg : messages.length
          });
           //console.log(messages);
        });
    }
	
	const pathname = this.props.location.pathname;
  		if(pathname==="/"){
	  
			window.addEventListener('scroll', () => {
				 let activeClass = 'head-fix';
				 if(window.scrollY === 0){
					 activeClass = '';
				 }
				 this.setState({ activeClass });
			  });
		}
  }

 
  handleProfileMenuOpen = event => {
    this.setState({
      setAnchorEl : event.currentTarget,
      anchorEl : event.currentTarget,
      isMenuOpen : true
    })
  };
  handleMenuClose = event => {
    const { myValue } = event.currentTarget.dataset;
    this.setState({
      setAnchorEl : null,
      isMenuOpen : false
    })
    this.props.history.push(myValue);
  };

  handleMobileMenuClose = event => {
    this.setState({
      setMobileMoreAnchorEl : null,
      isMobileMenuOpen : false
    })
  }

  handleMobileMenuOpen = event => {
    //console.log(event.currentTarget)
    this.setState({
      setMobileMoreAnchorEl : event.currentTarget,
      mobileMoreAnchorEl: event.currentTarget,
      isMobileMenuOpen : true
    })
  }

  handleNavigation(id, e){
    this.props.history.push(id);
  }

  toggleDrawer = (event) => {
    console.log(event.type);
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    const isOpen = !this.state.drawerIsOpen;
    this.setState({ drawerIsOpen: isOpen });
  };

   handleDrawerClose = () => {
    this.setState({ drawerIsOpen: false });
  };

  
render() {
  const menuId = 'primary-search-account-menu';
  const nickname = localStorage.getItem('nickname');
  //const userType = localStorage.getItem('userRole');
  const userId = localStorage.getItem('id');
  const pathname = this.props.location.pathname;
  let homeClass= "";
  if(pathname==="/"){
	  homeClass = "home_container";
  }
  return (
  <div className={this.state.activeClass+' '+homeClass}>
  	 <header>
			  <div className="row">
				<div className="col s12">
				  <nav className="transparent" role="navigation">
					<div className="nav-wrapper container">
					   <Link onClick={this.handleNavigation.bind(this,ROUTES.LANDING)} to={ROUTES.LANDING} className="brand-logo">
              <img src="/images/logo.png" alt=""/>
             </Link>
					   {
               (userId==="" || userId===null)
  					 ? <ul className="right hide-on-med-and-down">
  						  <li><a href={ROUTES.SIGN_IN}>Login</a></li>
  						  <li><a href={ROUTES.SIGN_UP}>Sign up</a></li>
  					  </ul>
              : <Toolbar className="right hide-on-med-and-down">
                    <IconButton 
                        aria-label="show new notifications" 
                        color="inherit" 
                        aria-controls={mobileMenuId}
                        onClick={this.handleMobileMenuOpen}
                        className="notificationIcon"
                    >
                        <Badge badgeContent={this.state.totalMsg} color="secondary">
                          <NotificationsNoneRounded />
                        </Badge>
                    </IconButton>

                    <IconButton
                        edge="end"
                        aria-label="Account of current user"
                        aria-controls={menuId}
                        aria-haspopup="true"
                        onClick={this.handleProfileMenuOpen}
                        color="inherit"
                      >

                        <AccountCircle />
                        <Typography variant="body1" className="currentUserName"> { nickname }</Typography>
                    </IconButton>
                  </Toolbar>
                }

               <MenuIcon className="right sidenav-trigger" onClick={this.toggleDrawer} /> 

              </div>
				  </nav>
				</div>
			  </div>
			</header>
		 <div>
    <Drawer
       anchor="right"
	   className="mobile-nav"
        open={this.state.drawerIsOpen}
         onClose={this.toggleDrawer}
         variant="temporary"
      >
        <div 
          onClick={this.toggleDrawer}
          onKeyDown={this.toggleDrawer}
        >
          <IconButton className="userMobileMenuBtn" onClick={this.toggleDrawer}>
            X
          </IconButton>
        </div>
        {
               (userId==="" || userId===null)
             ? <ul className="right">
                <li><a href={ROUTES.SIGN_IN}>Login</a></li>
                <li><a href={ROUTES.SIGN_UP}>Sign up</a></li>
              </ul>
              : <Toolbar className="right">
                   

                    
                    <ul className="right userMobileMenu">
					<li> <IconButton 
                        aria-label="show new notifications" 
                        color="inherit" 
                        aria-controls={mobileMenuId}
                        onClick={this.handleMobileMenuOpen}
                        className="notificationIcon mobile"
                    >
                        <Badge badgeContent={this.state.totalMsg} color="secondary">
                          <NotificationsNoneRounded />
                        </Badge>
                    </IconButton></li>
                      <li>
                        <Typography variant="body1" className="currentUserName"><AccountCircle /> { nickname }</Typography>
                      </li>
                      <li><a href={ROUTES.HOME}>Dashboard</a></li>
                      <li><a href={ROUTES.ACCOUNT}>Account</a></li>
                      <li><SignOutButton /></li>
                    </ul>
                    
                  </Toolbar>
                }
      </Drawer>

    <ToastContainer />
    <Menu
      anchorEl={this.state.anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={this.state.isMenuOpen}
      onClose={this.handleMenuClose}
    >
      <AuthUserContext.Consumer>
    {authUser => 
      authUser ? <div>
      <MenuItem data-my-value={ROUTES.HOME} onClick={this.handleMenuClose}>Dashboard</MenuItem>
      <MenuItem data-my-value={ROUTES.ACCOUNT} onClick={this.handleMenuClose}>Account</MenuItem>
      {
        localStorage.getItem('userRole')==="Denturist"
        ? <MenuItem data-my-value={ROUTES.CASE_CREATE} onClick={this.handleMenuClose}>Add Job</MenuItem>
        : ''
      }
          <SignOutButton />
      </div> : <div><MenuItem data-my-value={ROUTES.LANDING} onClick={this.handleMenuClose}>Landing</MenuItem>
      <MenuItem data-my-value={ROUTES.SIGN_IN} onClick={this.handleMenuClose}>Sign In</MenuItem></div>
    }
    </AuthUserContext.Consumer>
    </Menu>

    <Menu
      anchorEl={this.state.mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={this.state.isMobileMenuOpen}
      onClose={this.handleMobileMenuClose}
      className="menuContainer"
      PaperProps={{
        style: {
          maxHeight: ITEM_HEIGHT * 5,
        },
      }}
    >
        <Paper square>
        <Typography variant="h5" gutterBottom>
          Notifications
        </Typography>
        
          <Notifications />
          {/*messages.map(({ id, primary, secondary }) => (
            <React.Fragment key={id}>
              <ListItem button>
                <ListItemText primary={primary} secondary={secondary} />
              </ListItem>
            </React.Fragment>
          ))*/}
        
      </Paper>
    </Menu>

	</div>
    
  </div>
);
  }
}

export default compose(withRouter, withFirebase)(Navigation);
//export default withRouter(Navigation);