import React, { Component } from "react";
import Dropzone from "./../dropzone/Dropzone";
import "./Upload.css";
import Progress from "./../progress/Progress";
import firebase from 'firebase';
import { withFirebase } from '../Firebase';
import { AppString } from '../Const';
import Paper from '@material-ui/core/Paper';
import circlesvg from "./../../images/check-square.svg"
import PublishTwoToneIcon from '@material-ui/icons/PublishTwoTone';
import ClearIcon from '@material-ui/icons/Clear';





class FirebaseFileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      uploading: false,
      uploadProgress: {},
      successfullUploaded: false
    };

    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.renderActions = this.renderActions.bind(this);
    //console.log(this.props);
  }

  componentDidMount(){

  }

  onFilesAdded(files) {
      if(this.props.directory === 'images') {
          //todo: check only images are allowed
      }
    this.setState(prevState => ({
      files: prevState.files.concat(files)
    }));
  }

  async uploadFiles() {
    this.setState({ uploadProgress: {}, uploading: true });
    const promises = [];
    this.state.files.forEach(file => {
      promises.push(this.sendRequest(file));
    });
    try {
      await Promise.all(promises);

      this.setState({ successfullUploaded: true, uploading: false });
    } catch (e) {
      // Not Production ready! Do some error handling here instead...
      this.setState({ successfullUploaded: true, uploading: false });
    }
  }

  sendRequest(file) {
      const that = this;
      const dir = this.props.directory;
      //var storageRef = (this.props.directory === 'images')?this.props.firebase.imagesStore.ref():this.props.firebase.documentsStore;
      var dbRef = (this.props.directory === 'images')?this.props.firebase.imagesDB:this.props.firebase.documentsDB;
      //var docRef = this.props.firebase.imagesStore().child(this.props.directory + '/' + file.name);
      var docRef = this.props.firebase.fileStorage().child(this.props.directory + '/' + file.name);
      var uploadTask = docRef.put(file);
      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, function(snapshot){
          //todo: use the following to fill up progress and error handling etc.
        //https://firebase.google.com/docs/storage/web/upload-files
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        const copy = { ...that.state.uploadProgress };
        copy[file.name] = {
         state: "pending",
         percentage: progress
        };
        that.setState({ uploadProgress: copy });

      },
      function(error) {
        console.log(error);
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "error", percentage: 0 };
        this.setState({ uploadProgress: copy });
      },
      function() {

        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                dbRef().add({
                filename: file.name,
                filetype: that.props.directory,
                userID: localStorage.getItem(AppString.ID),
                userName: localStorage.getItem(AppString.NICKNAME),
                downloadUrl: downloadURL,
                datetime: new Date(),
                job: that.props.jobId
            })

            const copy = { ...that.state.uploadProgress };
            copy[file.name] = { state: "done", percentage: 100 };
            that.setState({ uploadProgress: copy });

        });
        that.props.isuploaded(dir)
      }
      );
  }

  renderProgress(file) {
    const uploadProgress = this.state.uploadProgress[file.name];
    if (this.state.uploading || this.state.successfullUploaded) {
      return (
        <div className="ProgressWrapper">
          <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
          <img
            className="CheckIcon"
            alt="done"
            src={circlesvg} 
            style={{
              opacity:
                uploadProgress && uploadProgress.state === "done" ? 0.5 : 0
            }}
          />
        </div>
      );
    }
  }

  renderActions() {
    if (this.state.successfullUploaded) {
      return (
        <button
          onClick={() =>
            this.setState({ files: [], successfullUploaded: false })
          }
        >
		<ClearIcon fontSize="small" className="icon-size"/>
          CLEAR
        </button>
      );
    } else {
      return (
        <button
          disabled={this.state.files.length < 0 || this.state.uploading}
          onClick={this.uploadFiles}
        >
		<PublishTwoToneIcon fontSize="small" className="icon-size"/>


          UPLOAD
        </button>
      );
    }
  }

  render() {
    return (
        <div><Paper>
      <div className="Upload">
          
        <div className="Content">
          
            <Dropzone
              onFilesAdded={this.onFilesAdded}
              disabled={this.state.uploading || this.state.successfullUploaded}
            />
          
          <div className="Files">
            {this.state.files.map(file => {
              return (
                <div key={file.name} className="Row">
                  <span className="Filename">{file.name}</span>
                  {this.renderProgress(file)}
                </div>
              );
            })}
          </div>
        </div>
        <div className="Actions">{this.renderActions()}</div>
        </div>
        </Paper>
      </div>
    );
  }
}

export default withFirebase(FirebaseFileUpload);
