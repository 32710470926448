import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import firebase from 'firebase';
import {withFirebase} from './../Firebase';
import {  Breadcrumbs, Grid, Paper, Typography, TableRow, TableCell, Container, Table, TableBody, Select, MenuItem, OutlinedInput} from '@material-ui/core';
import moment from 'moment';
//import { makeStyles } from '@material-ui/core/styles';
import ImageGallery from './../Gallery/ImageGallery';
import DocumentList from './../Gallery/DocumentList';
import FirebaseFileUpload from './../FileUpload/FirebaseFileUpload';
import WelcomeBoard from './../messaging/WelcomeBoard/WelcomeBoard';
import ChatBoard from './../messaging/ChatBoard/ChatBoard';
import images from './../../images/Images'
import { compose } from "recompose";
import './case.css';
import { AppString } from './../../components/Const';
import * as ROUTES from '../../constants/routes';
import { toast } from 'react-toastify';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import 'react-toastify/dist/ReactToastify.css';
import EditIcon from '@material-ui/icons/Edit';
import HighlightOffTwoToneIcon from '@material-ui/icons/HighlightOffTwoTone';
import CheckIcon from '@material-ui/icons/Check';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReactLoading from 'react-loading';
import Button from '@material-ui/core/Button';

class CaseShow extends Component {

  constructor(props) {
    super(props);
    this.getUsers = this.getUsers.bind(this);
    this.state = {
      Title:'',
      Description:'',
      thisDate:'',
      Date: '',
      key:'',
      jobID:this.props.match.params.id,
      documentUploaded: false,
      imageUploaded: false,
      users: [],
      peerUsers: [],
      currentUserId : '',
      currentUserAvatar: '',
      currentUserNickname: '',
      currentPeerUser: { userId: '' },
      userRole: localStorage.getItem('userRole'),
      denturistName: '',
      denturist : '',
      designer: '',
      mill: '',
      lastUpdated: '',
      status: '',
      jobType: [],
      msg_title: '',
      msg: '',
      open: false,
      deleteJobId: ''
    };
    
    const user_id = localStorage.getItem(AppString.ID);
    if(!user_id){
      this.props.history.push(ROUTES.SIGN_IN);
    }

  }

  listenToChange(doc){
    //console.log(this.state.documentUploaded)
    //alert(doc)
    if(doc==="images"){
      this.setState({ imageUploaded : !this.state.imageUploaded  });
    }else{
      this.setState({ documentUploaded : true });
    }
    //this.componentDidMount()
    //console.log(this.state.documentUploaded)
  }

  componentDidMount() {
    document.title = 'Job Detail - Digital Denture Experts'
    const docRef = this.props.firebase.caseDB().doc(this.props.match.params.id);
    docRef.get({source:'server'}).then(doc => {
    if (doc.exists) {
       const job = doc.data();
       this.setState({
          Title: job.Title,
          Description:job.Description,
          thisDate:job.Date.seconds,
          Date: firebase.firestore.Timestamp.fromMillis(job.Date.seconds * 1000),
          denturist: job.denturist,
          designer: job.designer,
          mill:job.mill,
          key: doc.id,
          isLoading: false,
          status: job.status,
          users: job.users,
          lastUpdated: job.lastUpdated.seconds,
          jobType: job.jobType,
          payment_status: job.payment_status
        });
        //this.getDenturist(job.denturist);
        this.getUsers();
      } else {
        console.log("No such document!");
        toast("Please check your URL. Either the URL is invalid or the job doesn't exists", { position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
            type: 'success', onClose: () => { this.props.history.push("/home") }
        });

      }
    });

   
  }

  delete(id){
    this.props.firebase.caseDB().doc(id).delete().then(() => {
      console.log("Document successfully deleted!");
      this.props.history.push("/")
    }).catch((error) => {
      console.error("Error removing document: ", error);
    });
  }

  getUsers(){
    const {users}  = this.state;
    var that = this;
    let peerUsers = [];
    firebase.auth().onAuthStateChanged((thisUser) => {
        if(thisUser){
        return users.map(function(user_id, key){
             that.props.firebase.usersDB().where("userId", "==", user_id)
              .get({source:'server'})
              .then(function(querySnapshot) {
                  querySnapshot.forEach(function(doc) {
                      // doc.data() is never undefined for query doc snapshots
                      //console.log(doc.id, " => ", doc.data());
                      //console.log(doc.data());
                      const u = doc.data();
                      
                      if(u.userRole==="Denturist"){
                        //console.log("in userRole"+u.userRole);
                        that.setState({
                          denturistName : u.username
                        });
                      }


                      if(thisUser.uid!==user_id){
                        peerUsers.push({...doc.data(), id: doc.id});
                      }else{
                        that.setState({ 
                          currentUserId: doc.id, 
                          currentUserNickname: doc.data().username, 
                          currentUserAvatar : doc.data().photoUrl ? doc.data().photoUrl : images.ic_avatar
                        })
                      }
                  });
                  //code to default open a chat
                  let currentPeerUser = {userId: ''};
                  if(peerUsers.length===1){
                      currentPeerUser = peerUsers[0];
                  }
                  //code to default open a chat end

                  that.setState({ peerUsers : peerUsers, currentUserId : thisUser.uid, currentPeerUser: currentPeerUser })
              })
              .catch(function(error) {
                  console.log("Error getting documents: ", error);
              });


        })
      }
    })
  }

  renderListUser = () => {
    const that = this;
  
    if (this.state.peerUsers.length > 0) {
        let viewListUser = []
        this.state.peerUsers.forEach((item, index) => {
            let img = "";
            if (item.userId !== this.state.currentUserId) {
                if(item.photoUrl==="" || item.photoUrl===undefined){
                  img = images.ic_avatar
                }else{
                  img = item.photoUrl
                }
                
                viewListUser.push( <
                    button className = {
                        that.state.currentPeerUser &&
                        that.state.currentPeerUser.userId === item.userId ?
                        'viewWrapItemFocused' :
                            'viewWrapItem'
                    }
                    key = {
                        item.userId
                    }
                    onClick = {
                        () => {
                            that.setState({
                                currentPeerUser: item
                            })
                        }
                    } >
                    <
                    img className = "viewAvatarItem"
                    src = {
                        img
                    }
                    alt = "icon avatar" /
                    >
                    <
                    div className = "viewWrapContentItem" >
                    <
                    span className = "textItem" > {
                        `${
                item.username
              }`
                    } < /span>  <
                    /div> <
                    /button>
                )
            }
        })
        return viewListUser
    } else {
        return null
    }
}

handleClickOpen = (id,tes, e ) => {
    e.preventDefault();
    let msg_title, msg = "";
    if(tes==="delete"){
      msg_title = "Delete Job";
      msg = "Are you sure to delete this job? You will not be able to undo this action.";
    }else{
      msg_title = "Mark Job As Completed";
      msg = "Are you sure to mark this job as completed? You will not be able to undo this action.";
    }
    this.setState({
      open : true,
      msg_title: msg_title,
      msg: msg,
      deleteJobId: id
    }); 
  }

  handleClose = (e) => {
    e.preventDefault();
    this.setState({
      open : false,
      deleteJobId : '',
      isLoading: false
    }); 
  }

  markCompleted(id,e){
    e.preventDefault();
    this.setState({ isLoading: true })
    const docRef = this.props.firebase.caseDB().doc(id);
    docRef.get({source:'server'}).then(doc => {
    if (doc.exists) {
       const job = doc.data();
       const { Title, Description, Date, mill, designer, denturist, users, jobType } = doc.data();
       //console.log(Title)
       const status = 'Completed';
        const lastUpdated = firebase.firestore.Timestamp.fromMillis(moment());
        const updateRef = this.props.firebase.caseDB().doc(id);
        updateRef.set({
          Title,
          Description,
          mill,
          designer,
          Date,
          denturist,
          users,
          lastUpdated,
          status,
          jobType
        }).then((docRef) => {
            const u = [];
            u.push('admin');
            const user_id = denturist;
            const addDate = lastUpdated;
            const caseId = id;
            const fromUser = user_id;
            const toUsers = u;
            const isRead = false;
            const subject = "Status Changed";
            const jobStatus = status;
            const message = "Job "+ job.Title+" "+status+" by denturist";
            this.props.firebase.notificationsDB().add({ addDate,caseId,fromUser,toUsers,isRead,subject,message,jobStatus}).then(() => {
                  //console.log('tets')
            })
          
           toast('Successfully Updated', { position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
              type: 'success', onClose: () => { 
                this.setState({ isLoading: false });
                this.handleClose(e); 
              }
          });
          
        })
          .catch((error) => {
            console.error("Error adding document: ", error);
            toast(error.message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
                type: 'error',
            });
          });
      } else {
            toast("Error updating document", { position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
                type: 'error',
            });
      }
    });
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
    this.updateJob(event.target.value);
  }

  updateJob(statusVal){
    const { Title, Description, Date, mill, designer, denturist, users, jobType } = this.state;
    //const status = this.state.status;
    const status = statusVal;
    const lastUpdated = firebase.firestore.Timestamp.fromMillis(moment());
    const updateRef = this.props.firebase.caseDB().doc(this.state.key);
    updateRef.set({
      Title,
      Description,
      mill,
      designer,
      Date,
      denturist,
      users,
      lastUpdated,
      status,
      jobType
    }).then((docRef) => {
        const u = [];
        u.push(denturist);
        const user_id = localStorage.getItem(AppString.ID);
        const addDate = lastUpdated;
        const caseId = this.state.key;
        const fromUser = user_id;
        const toUsers = u;
        const isRead = false;
        const subject = "Status Changed";
        const jobStatus = statusVal;
        const message = "Job "+ this.state.Title+" "+status+" by "+this.state.userRole;
        this.props.firebase.notificationsDB().add({ addDate,caseId,fromUser,toUsers,isRead,subject,message,jobStatus}).then(() => {
              //console.log('tets')
        })
      
       toast('Successfully Updated', { position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
          type: 'success', onClose: () => { this.props.history.push(ROUTES.CASE_DISPLAY + this.props.match.params.id); }
      });
      
    })
      .catch((error) => {
        console.error("Error adding document: ", error);
        toast(error.message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
            type: 'success',
        });
      });
  }

  assignedUser(){
    //console.log(this.state.peerUsers)
    return this.state.peerUsers.map(function(obj, key){
        return <p key={key}>{obj.username}</p> 
    });
  }

  renderStatus = () => {
    //console.log(this.state);
    if (this.state.userRole==="Designer") {
      return  <Select
                      className="status_select"
                      value={this.state.status}
                      onChange={this.handleChange}
                      name="status"
                      id="status"
                      input={<OutlinedInput name="status" id="outlined-age-simple" />}
                      displayEmpty
                    >
                    <MenuItem value="" disabled>Change Status</MenuItem>
                    <MenuItem value="Assigned">Assigned</MenuItem>
                    <MenuItem value="Design Job Accepted">Design Job Accepted</MenuItem>
                    <MenuItem value="Design Under Progress">Design Under Progress</MenuItem>
                    <MenuItem value="Design Completed">Design Completed</MenuItem>
              </Select>;
    }else if(this.state.userRole==="Mill"){
      return  <Select
                      className="status_select"
                      value={this.state.status}
                      onChange={this.handleChange}
                      name="status"
                      id="status"
                      input={<OutlinedInput name="status" id="outlined-age-simple" />}
                      displayEmpty
                    >
                    <MenuItem value="" disabled>Change Status</MenuItem>
                    <MenuItem value="Assigned">Assigned</MenuItem>
                    <MenuItem value="Mill Job Accepted">Mill Job Accepted</MenuItem>
                    <MenuItem value="Milling Under Progress">Milling Under Progress</MenuItem>
                    <MenuItem value="Milling Completed">Milling Completed</MenuItem>
              </Select>;
    }else{
      return <p>{this.state.status} {
                      this.state.payment_status==="Paid"
                      ? <span className="case_paid" style={{marginLeft: '10px'}}>Paid</span>
                      : ''
                    }
                    </p>
    }
  }

  render() {
    //const user_id = localStorage.getItem(AppString.ID);
    //console.log(this.state.status);
    let case_id = this.props.match.params.id;
    return (
      <div>
       
       <Dialog
            maxWidth='xs'
            disableBackdropClick={true}
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
        <DialogTitle id="alert-dialog-title">{this.state.msg_title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {this.state.msg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={this.handleClose} color="default">
            Cancel
          </Button>
          {
            this.state.msg==="delete"
            ? <Button variant="contained" onClick={this.delete.bind(this,this.state.deleteJobId)} color="secondary">
                {this.state.isLoading ? (
                <div>
                  <ReactLoading
                    type={'spin'}
                    color={'#fff'}
                    height={'20%'}
                    width={'20%'}
                    className="tstmenu"
                  />
                  Deleting
                </div>
              ) : 'Delete'}
                  
                </Button>
            : <Button variant="contained" onClick={this.markCompleted.bind(this,this.state.deleteJobId)} color="secondary">
                {this.state.isLoading ? (
                <div>
                  <ReactLoading
                    type={'spin'}
                    color={'#fff'}
                    height={'20%'}
                    width={'20%'}
                    className="tstmenu"
                  />
                  Processing
                </div>
              ) : 'Mark Complete'}
                  
                </Button>
            }
        </DialogActions>
      </Dialog>
    
        <Breadcrumbs className="nav-breadcrumbs" separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link color="inherit" to={ROUTES.HOME}>
            Jobs
          </Link>
          <Typography color="textPrimary">{this.state.Title}</Typography>
        </Breadcrumbs>
    
      <div className="container">
     <div className="common-box">
        <Container maxWidth="lg">
        <Grid container  spacing={2}>
          <Grid className="job-dec box-shadow" item md={4} xs={12}>
           <Paper>
           <div className="dflow">
            <Table >
              <TableBody>
              <TableRow>
                <TableCell width="30%">
                  <Typography variant="subtitle1">
                    Job:
                  </Typography>
                </TableCell>
                <TableCell>
                <Typography variant="subtitle2">
                    {this.state.Title}
                  </Typography>
                </TableCell>
                </TableRow>
                <TableRow>
                <TableCell>
                  <Typography variant="subtitle1">
                    Description:
                  </Typography>
                </TableCell>
                <TableCell>
                <Typography variant="subtitle2">
                    {this.state.Description}
                  </Typography>
                </TableCell>
                </TableRow>
                <TableRow>
                <TableCell>
                  <Typography variant="subtitle1">
                    Posted Date:
                  </Typography>
                </TableCell>
                <TableCell>
                <Typography variant="subtitle2">
                    {moment.unix(this.state.thisDate).format('MMM DD, YYYY')}
                  </Typography>
                </TableCell>
                </TableRow>
                <TableRow>
                <TableCell>
                  <Typography variant="subtitle1">
                    Last Updated:
                  </Typography>
                </TableCell>
                <TableCell>
                <Typography variant="subtitle2">
                    {moment.unix(this.state.lastUpdated).format('MMM DD, YYYY')}
                  </Typography>
                </TableCell>
                </TableRow>

                <TableRow>
                <TableCell>
                  <Typography variant="subtitle1">
                    Denturist:
                  </Typography>
                </TableCell>
                <TableCell>
                <Typography variant="subtitle2">
                    {this.state.denturistName}
                  </Typography>
                </TableCell>
                </TableRow>

                <TableRow>
                <TableCell>
                  <Typography variant="subtitle1">
                    Assigned To:
                  </Typography>
                </TableCell>
                <TableCell>
                <Typography variant="subtitle2">
                    {/*moment.unix(this.state.lastUpdated).format('MMM DD, YYYY hh:mm A')*/}
                    { this.assignedUser() }
                  </Typography>
                </TableCell>
                </TableRow>

                <TableRow>
                <TableCell>
                  <Typography variant="subtitle1">
                    Status:
                  </Typography>
                </TableCell>
                <TableCell>
                <Typography variant="subtitle2">
                    { this.renderStatus()}
                    
                    
                  </Typography>
                </TableCell>
                </TableRow>

                </TableBody>
            </Table>
          </div>
          {
            (this.state.userRole==='Denturist')
            ? <div className="btn-row">
              <Link to={ROUTES.CASE_EDIT + this.state.key} className="btn btn-success btn-orange round-btn"><EditIcon fontSize="small" className="icon-size" /> Edit </Link>&nbsp;
              <button onClick={this.delete.bind(this, this.state.key)} className="btn btn-danger round-btn"><HighlightOffTwoToneIcon fontSize="small" className="icon-size" /> Delete</button>
              {
                this.state.status!=="Completed"
                ? <Link to={'/'} data-target="mark_complete" data-title="Mark Completed" onClick={this.handleClickOpen.bind(this, this.state.key, 'mark_completed')}  className="btn btn-orange round-btn"><CheckIcon fontSize="small" className="icon-size" /> Mark Complete</Link>
                : ''
              }
              
            </div>
            : ''
          }
          </Paper>
          </Grid>
         
	<Grid className="doc_wrapper" item md={8} xs={12}>

          	<Grid className="documents  box-shadow" item md={12} xs={12}>
              <Paper>
                  <Typography variant="h5" component="h4">
                      Uploaded Documents
                  </Typography>
                  <div className="upload-box">
                      <DocumentList refresh={this.state.documentUploaded} jobId={this.state.jobID}/>
                  </div>
                      <FirebaseFileUpload isuploaded={this.listenToChange.bind(this)} directory={'documents'} jobId={this.state.jobID}/>
              </Paper>
          	</Grid>

          	<Grid className="gallery  box-shadow" item md={12} xs={12}>
	            <Paper>
	              <div className="">
	                <Typography variant="h5" component="h4">
	                    Image Gallery
	                </Typography>
	                <div className="img-box">
	                  <ImageGallery refreshImage={this.state.imageUploaded} jobId={this.state.jobID}/> 
	                </div>
	                <FirebaseFileUpload isuploaded={this.listenToChange.bind(this)} jobId={this.state.jobID} directory={'images'} />
	                </div>
	             </Paper>
	        </Grid>
          </Grid>
                
                <Grid item md={12} xs={12} className="peerMessaging  box-shadow">
                    <Paper className="chatContainer">
                        <Typography variant="h5" component="h3">
                            Peer to peer messaging
                        </Typography>
                        
                        { 
                          this.state.peerUsers.length > 0
                          ? <div className="viewListUser"> { this.renderListUser() } </div>
                          : ''
                        }
                        
                       {this.state.currentPeerUser.userId!=="" ? (
                          <ChatBoard
                            currentPeerUser={this.state.currentPeerUser}
                            currentUserNickname={this.state.currentUserNickname} 
                            currentUserAvatar={this.state.currentUserAvatar} 
                            currentUserId={this.state.currentUserId} 
                            caseId = {case_id}
                          />
                        ) : (
                          <WelcomeBoard
                            currentUserNickname={this.state.currentUserNickname} 
                              currentUserAvatar={this.state.currentUserAvatar} 
                          />
                        )}
                        {/* this.state.currentUserId
                          ? <WelcomeBoard 
                              currentUserNickname={this.state.currentUserNickname} 
                              currentUserAvatar={this.state.currentUserAvatar} 
                            />
                          : ''
                        */}
                        
                        
                    </Paper>
                </Grid>
            </Grid>
            </Container>
            </div>
      </div>
      </div>
    );
  }
}

//export default withFirebase(CaseShow);
export default compose(withFirebase)(CaseShow);