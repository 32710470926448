import React, { Component } from "react";
import PropTypes from 'prop-types';
import { withFirebase } from '../Firebase';
import { withStyles, GridList, GridListTile } from '@material-ui/core';
import { compose } from "recompose";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const useStyles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
});


class ImageGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      photoIndex: 0,
      isOpen: false,
      imageUrls: [],
    }

  }
  //const classes = useStyles();
  fetchRecord() {
    //console.log('in fetchRecord')
    this.setState({ loading: true });
    const docRef = this.props.firebase.imagesDB().where('job', '==', this.props.jobId);
    docRef.get({source:'server'}).then((querySnapshot) => {
      let images = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        const img = doc.data().downloadUrl;
        //const docId = doc.id;
        if (doc.data().filetype === 'images') {
          images.push(doc);
          this.state.imageUrls.push(img);
        }
      });
      this.setState({
        images: images,
        loading: false,
      });
      
    });
  }

  fetchNewRecord() {
    //console.log('in fetchRecord')
    this.setState({ loading: true });
    const docRef = this.props.firebase.imagesDB().where('job', '==', this.props.jobId);
    docRef.get({source:'server'}).then((querySnapshot) => {
      let images = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        const img = doc.data().downloadUrl;
        //const docId = doc.id;
        if (doc.data().filetype === 'images') {
          images.push(doc);
          this.state.imageUrls.push(img);
        }
      });
      /*this.setState({
        images: images,
        loading: false,
      });*/
      this.setState({ images: images,
        loading: false, }, function () {
             this.fetchRecord()
        });
    });
  }

  componentDidMount() {

  this.fetchRecord()
}

  componentWillUnmount() {
    if (this.removeListener) {
      this.removeListener();
    }
  }

  stateFunc(key, e){
      e.preventDefault();
      //console.log(key)
      this.setState({ isOpen: true, photoIndex : key });
  }

  componentWillReceiveProps(props) {
  //const { refreshImage } = this.props;
  //console.log(this.props.refreshImage+'===='+refreshImage+'========='+props.refreshImage);
  //if (props.refreshImage) {
    this.fetchNewRecord()
    console.log('received')
    //this.forceUpdate()
  //}
}


  render() {
    const { classes } = this.props;
    const { isOpen, photoIndex,imageUrls, images } = this.state;
    //console.log('imageUrls',imageUrls)
    //console.log('images',images)
    return (

      <div className={classes.root}>
        <GridList cellHeight={90} className={classes.gridList} cols={5}>
          {
            images.map((tile, idx)=> {
             return <GridListTile key={tile.id}>
                <a href={tile.data().downloadUrl}  onClick={(e) => this.stateFunc(idx,e)}>
                  <img src={tile.data().downloadUrl} alt={tile.data().filename} />
                </a>
              </GridListTile>
            })
          }
        </GridList>
        {isOpen && (
          <Lightbox
            discourageDownloads = {true}
            mainSrc={imageUrls[photoIndex]}
            nextSrc={imageUrls[(photoIndex + 1) % imageUrls.length]}
            prevSrc={imageUrls[(photoIndex + imageUrls.length - 1) % imageUrls.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + imageUrls.length - 1) % imageUrls.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % imageUrls.length,
              })
            }
          />
        )}
      </div>
    );
  }
}
ImageGallery.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(withStyles(useStyles), withFirebase)(ImageGallery);
//export default withStyles(useStyles)(ImageGallery);