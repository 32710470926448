import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import firebase from 'firebase';
import {withFirebase} from './../Firebase';
import { Table, TableRow, TableCell, TableHead, TableBody, Typography, Paper, Tooltip } from '@material-ui/core';
import moment from 'moment';
//import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DeleteOutline, ListOutlined, EditOutlined } from '@material-ui/icons';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import { compose } from "recompose";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReactLoading from 'react-loading';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import AddCircleOutlineSharpIcon from '@material-ui/icons/AddCircleOutlineSharp';
import CheckIcon from '@material-ui/icons/Check';

//import {AuthUserContext} from './../Session';

import * as ROUTES from '../../constants/routes';

class Case extends Component {
    constructor(props) {
        super(props);
        this.state = {
          docs: [],
          loading:true,
          userRole: localStorage.getItem('userRole'),
          currentPage: 1,
          itemsPerPage : 100,
          open: false,
          deleteJobId : '',
          isLoading: false,
          selectedJobFilter : 'All',
          job_type: '',
          millers: [],
          designers: [],
          showClass: '',
          mill: '',
          designer: '',
          filterUser: '',
          msg_title: '',
          msg: '',
          status: '',
          loadedPosts: {},
          isLoadedMsg:""
        }
        this.removeListener = null
    }

  handleClickOpen = (id,tes, e ) => {
    e.preventDefault();
    let msg_title, msg = "";
    if(tes==="delete"){
      msg_title = "Delete Job";
      msg = "Are you sure to delete this job? You will not be able to undo this action.";
    }else{
      msg_title = "Mark Job As Completed";
      msg = "Are you sure to mark this job as completed? You will not be able to undo this action.";
    }
    this.setState({
      open : true,
      msg_title: msg_title,
      msg: msg,
      deleteJobId: id
    }); 
    //alert(msg)
  }

  handleClose = (e) => {
    e.preventDefault();
    this.setState({
      open : false,
      deleteJobId : '',
      isLoading: false
    }); 
  }

  delete(id,e){
    e.preventDefault();
    this.setState({ isLoading: true })
    const that = this;
    //console.log(id);
    this.props.firebase.caseDB().doc(id).delete().then(() => {
      this.setState({ isLoading: false })
      this.handleClose(e);
      toast('Job successfully deleted', { position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
          type: 'success', onClose: () => { that.componentDidMount(); }
      });
    }).catch((error) => {
      console.error("Error deleting job: ", error);
      toast(error.message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
            type: 'error',
        });
    });
  }

  markCompleted(id,e){
    e.preventDefault();
    this.setState({ isLoading: true })
    const docRef = this.props.firebase.caseDB().doc(id);
    docRef.get({source:'server'}).then(doc => {
    if (doc.exists) {
       const job = doc.data();
       const { Title, Description, Date, mill, designer, denturistName, designerName, millName, denturist, users, jobType } = doc.data();
       //console.log(Title)
       const status = 'Completed';
        const lastUpdated = firebase.firestore.Timestamp.fromMillis(moment());
        const updateRef = this.props.firebase.caseDB().doc(id);
        updateRef.set({
          Title,
          Description,
          mill,
          millName,
          designer,
          designerName,
          Date,
          denturist,
          denturistName,
          users,
          lastUpdated,
          status,
          jobType
        }).then((docRef) => {
            const u = [];
            u.push('admin');
            const user_id = denturist;
            const addDate = lastUpdated;
            const caseId = id;
            const fromUser = user_id;
            const toUsers = u;
            const isRead = false;
            const subject = "Status Changed";
            const jobStatus = status;
            const message = "Job "+ job.Title+" "+status+" by denturist";
            this.props.firebase.notificationsDB().add({ addDate,caseId,fromUser,toUsers,isRead,subject,message,jobStatus}).then(() => {
                  //console.log('tets')
            })
          
           toast('Successfully Updated', { position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
              type: 'success', onClose: () => { 
                this.getCases('All'); 
                this.setState({ isLoading: false });
                this.handleClose(e); 
              }
          });
          
        })
          .catch((error) => {
            console.error("Error adding document: ", error);
            toast(error.message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
                type: 'error',
            });
          });
      } else {
            toast("Error updating document", { position: toast.POSITION.TOP_CENTER, autoClose: 3000, 
                type: 'error',
            });
      }
    });
  }

  componentDidMount() {
   this.getCases('All');
   this.getUsers();
  }

  getUsers(){
    let m = [];
    let d = [];
    const thisUser = firebase.auth().currentUser.uid;
    const that = this;
    let dRef = this.props.firebase.caseDB().where('denturist', '==', thisUser);
      dRef.get().then((querySnapshot) => {
        
        querySnapshot.forEach((doc) => {

          that.props.firebase.usersDB().where("userId", "==", doc.data().mill)
            .get({source:'server'})
            .then(function(querySnapshots) {
                querySnapshots.forEach(function(docsss) {
                    m.push(docsss.data());
                    m=that.removeDuplicates(m, docsss.data().userId)
                });
            });

          that.props.firebase.usersDB().where("userId", "==", doc.data().designer)
            .get({source:'server'})
            .then(function(querySnapshots) {
                querySnapshots.forEach(function(docss) {
                    d.push(docss.data());
                    d=that.removeDuplicates(d, docss.data().userId)
                });
            });

        });
      });

      this.setState({
        millers:m,
        designers:d
      });
  }

  removeDuplicates(myArr, prop) {
    return myArr.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
}

  getCases(jobType){
    const { itemsPerPage, status, job_type, mill, designer } = this.state;
    //const startAt = currentPage * itemsPerPage - itemsPerPage;
    const thisUser = firebase.auth().currentUser.uid;
    let userType = this.state.userRole;
    const that = this;

    //const docRef= this.props.firebase.caseDB().where(userType.toLowerCase(), '==', thisUser);
    let docRef = this.props.firebase.caseDB().where(userType.toLowerCase(), '==', thisUser);  
    if(jobType==="All"){
      docRef = this.props.firebase.caseDB().where(userType.toLowerCase(), '==', thisUser); 
    }
    if(job_type!=="" && job_type!=="All"){
      docRef = docRef.where('jobType','array-contains',job_type);
    }
    if(mill!==""){
      docRef = docRef.where('mill','==',mill);
    }
    if(designer!==""){
      docRef = docRef.where('designer','==',designer);
    }
    //alert(status)
    if(status!==''){
      docRef = docRef.where('status','==',status);
    }


    /*if(jobType==="All"){
      docRef= this.props.firebase.caseDB().where(userType.toLowerCase(), '==', thisUser); 
    }else if(jobType==="Milling Jobs" || jobType==="Designing Jobs"){
      docRef= this.props.firebase.caseDB().where(userType.toLowerCase(), '==', thisUser).where('jobType','array-contains',jobType);
    }else if(jobType==="status" && this.state.status!==' '){
      docRef= this.props.firebase.caseDB().where(userType.toLowerCase(), '==', thisUser).where('status','==',status);
    }else{
      docRef= this.props.firebase.caseDB().where(userType.toLowerCase(), '==', thisUser).where('users','array-contains',jobType);
    }*/

    
    docRef.orderBy('Date','desc').limit(itemsPerPage).get().then((querySnapshot) => {
        let docs = [];
        let isLoadedMsg = [];
        //let {loadedPosts} = this.state;
        querySnapshot.forEach((doc) => {
          let jobType = doc.data().jobType[0];

          /*loadedPosts[doc.id] = doc.data();
          

          that.props.firebase.usersDB().where("userId", "==", doc.data().mill)
            .get({source:'server'})
            .then(function(querySnapshots) {
                querySnapshots.forEach(function(docsss) {
                    // doc.data() is never undefined for query doc snapshots
                    loadedPosts[doc.id].millerName = docsss.data().username;
                });
            });

          //this.state.designers.push(doc.data().designer);  

          that.props.firebase.usersDB().where("userId", "==", doc.data().designer)
            .get({source:'server'})
            .then(function(querySnapshots) {
                querySnapshots.forEach(function(docss) {
                    // doc.data() is never undefined for query doc snapshots
                    loadedPosts[doc.id].designerName = docss.data().username;
                });
            });*/
          let docId = doc.id;
          let last_visited = "";
          let timestamp = "";
          let msgFrom = "";
          let newMsg = 0;
          //let msgRef = that.props.firebase.msgDB()
          that.props.firebase.chatroomDB()
            .where("userId", "==", thisUser)
            .where("caseId", "==", doc.id)
            .orderBy("last_visited","desc")
            .limit(1)
            .get({ source: 'server' })
            .then((querySnapshot) => {
              //console.log("querySnapshot",querySnapshot.docs)
            if(querySnapshot.docs.length){
                querySnapshot.forEach(function(docss) {
                    // doc.data() is never undefined for query doc snapshots
                    last_visited = docss.data().last_visited;
                    //console.log('last_visited',docss.data().last_visited);
                });



                //.where("idTo", "==", thisUser).where("caseId", "==", doc.id);
                if(this.state.userRole==='Denturist'){
                  if(jobType==="Designing Jobs"){
                    msgFrom = doc.data().designer;
                  }else{
                    msgFrom = doc.data().mill;
                  }
                }else{
                  msgFrom = doc.data().denturist;
                }
                

                let groupChatId = "";
                if (
                  this.hashString(thisUser) <=
                  this.hashString(msgFrom)
                ) {
                  groupChatId = `${thisUser}-${msgFrom}-${doc.id}`
                } else {
                  groupChatId = `${msgFrom}-${thisUser}-${doc.id}`
                }
                //console.log(groupChatId)
                //isLoadedMsg=[];
                this.removeListener = that.props.firebase.msgDB().doc(groupChatId)
                .collection(groupChatId)
                .orderBy('timestamp','desc')
                .limit(1)
                .onSnapshot(
                  snapshot => {
                    snapshot.docChanges().forEach(change => {
                      timestamp = change.doc.data().timestamp;
                      //console.log('timestamp',change.doc.data());
                      //console.log('last_visited',last_visited);
                      if(last_visited < timestamp){
                        //console.log('in',doc.id)
                        newMsg = 1;
                        isLoadedMsg.push({[docId]: newMsg })
                        //isLoadedMsg=that.removeDuplicates(isLoadedMsg, docId)
                      }else{
                        isLoadedMsg.push({[docId]: 0 })
                        //isLoadedMsg=that.removeDuplicates(isLoadedMsg, docId)
                      }
                      that.forceUpdate();
                    })
                  },
                  err => {
                    
                  }
                )
               
                //console.log('last_visited',last_visited)
                //console.log('timestamp',timestamp)
            }
          });


          

          

            /*msgRef.get({ source: 'server' })
            .then((querySnapshot) => {
              console.log("querySnapshot",querySnapshot.docs)
            if(querySnapshot.docs.length){
                querySnapshot.forEach(function(msgs) {
                    // doc.data() is never undefined for query doc snapshots
                    console.log(msgs.data());
                });
              
            }
          });*/

          docs.push({...doc.data(), id: doc.id });
              
        });
        //console.log(docs);
        

        this.setState({
          isLoadedMsg: isLoadedMsg,
          docs,
          loading: false,
        });


      });
  }

  hashString = str => {
    let hash = 0
    for (let i = 0; i < str.length; i++) {
      hash += Math.pow(str.charCodeAt(i) * 31, str.length - i)
      hash = hash & hash // Convert to 32bit integer
    }
    return hash
  }
  
  handleChange = (event) => {
    /*this.setState({
      [event.target.name]: event.target.value,
      showClass: event.target.value
    });*/
    let mill = this.state.mill;
    let designer = this.state.designer;
    if(event.target.value==="All"){
      mill="";
      designer="";
    }

    if(event.target.value==="Milling Jobs"){
      designer="";
    }
    if(event.target.value==="Designing Jobs"){
      mill="";
    }

    this.setState({
      [event.target.name]: event.target.value,
      showClass: event.target.value,
      mill: mill,
      designer: designer      
    }, function () {
      this.getCases(event.target.value);
    }.bind(this));

    
  }

  handleUserChange = (event) => {
    /*this.setState({
      [event.target.name]: event.target.value,
    });*/

    this.setState({
        [event.target.name]: event.target.value,
    }, function () {
        if(event.target.name==="status"){
          this.getCases(event.target.name);
        }else{
          this.getCases(event.target.value);
        }
    }.bind(this));


    /*if(event.target.name==="status"){
      this.getCases(event.target.name);
    }else{
      this.getCases(event.target.value);
    }*/
  }
  /*componentDidUpdate(prevProps, prevState) {
    const isDifferentPage = this.state.currentPage !== prevState.currentPage
    if (isDifferentPage) this.getItems()
  }*/

  componentWillUnmount() {
    if (this.removeListener) {
      this.removeListener();
    }
  }

  newMessages(vars){
    
   return this.state.isLoadedMsg.map((val, idx)=> {
      console.log(val)
      return (
        <div key={idx}>{val[vars]}</div>
      )
    })
  }
 
 render() {

    const {job_type, docs, designers, millers, mill, designer, status } = this.state;
    //console.log('newMsg',isLoadedMsg);
    return (
          <div className="container">
          <Dialog
            maxWidth='xs'
            disableBackdropClick={true}
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
        <DialogTitle id="alert-dialog-title">{this.state.msg_title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {this.state.msg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={this.handleClose} color="default">
            Cancel
          </Button>
          {
            this.state.msg_title==="Delete Job"
            ? <Button variant="contained" onClick={this.delete.bind(this,this.state.deleteJobId)} color="secondary">
                {this.state.isLoading ? (
                <div>
                  <ReactLoading
                    type={'spin'}
                    color={'#fff'}
                    height={'20%'}
                    width={'20%'}
                    className="tstmenu"
                  />
                  Deleting
                </div>
              ) : 'Delete'}
                  
                </Button>
            : <Button variant="contained" onClick={this.markCompleted.bind(this,this.state.deleteJobId)} color="secondary">
                {this.state.isLoading ? (
                <div>
                  <ReactLoading
                    type={'spin'}
                    color={'#fff'}
                    height={'20%'}
                    width={'20%'}
                    className="tstmenu"
                  />
                  Processing
                </div>
              ) : 'Mark Complete'}
                  
                </Button>
            }
        </DialogActions>
      </Dialog>


      

      <div className="common-box newJobs">
        {
            (this.state.userRole==='Denturist')
            ? <Typography variant="h5" component="h3">
              <Link  style={{textDecoration: 'none'}} component="button" to={ROUTES.CASE_CREATE} className="btn btn-primary round-btn right add"><AddCircleOutlineSharpIcon fontSize="small" className="icon-size" /> Add New Job</Link>
            </Typography>
            : ''
          }

        {
          (this.state.userRole==='Denturist')
          ? <FormControl variant="outlined" style={{maxWidth:'184px', background:'#fff'}}>
              <InputLabel htmlFor="outlined-age-simple">
                Job Type
              </InputLabel>
              <Select
                outlined='outlined'
                autoWidth={true}
                value={job_type}
                onChange={this.handleChange}
                input={<OutlinedInput name="job_type" id="outlined-age-simple" />}
              >
                <MenuItem value='All'>All Jobs</MenuItem>
                <MenuItem value='Milling Jobs'>Milling Jobs</MenuItem>
                <MenuItem value='Designing Jobs'>Designing Jobs</MenuItem>
                
              </Select>
            </FormControl>
          : ''
        }  
        


          {
            this.state.showClass==="Milling Jobs"
            ? <FormControl variant="outlined" style={{maxWidth:'184px', background:'#fff', marginLeft: '20px'}}>
                  <InputLabel htmlFor="outlined-age-simple">
                    Millers
                  </InputLabel>
                  <Select
                    outlined='outlined'
                    autoWidth={true}
                    value={mill}
                    onChange={this.handleUserChange}
                    input={<OutlinedInput name="mill" id="outlined-age-simple" />}
                  >
                    {
                      millers.map((doc, i) => {
                        
                       return <MenuItem key={i} value={doc.userId}>{doc.username}</MenuItem>
                      })
                    }
                    
                  </Select>
                </FormControl>
              : ''
            }
      
      

            {
              this.state.showClass==="Designing Jobs"
              ? <FormControl variant="outlined" style={{maxWidth:'184px', background:'#fff', marginLeft: '20px'}}>
                  <InputLabel htmlFor="outlined-age-simple">
                    Designers
                  </InputLabel>
                  <Select
                    outlined='outlined'
                    autoWidth={true}
                    value={designer}
                    onChange={this.handleUserChange}
                    input={<OutlinedInput name="designer" id="outlined-age-simple" />}
                  >
                    {
                      designers.map((doc, i) => {
                        return <MenuItem key={i} value={doc.userId}>{doc.username}</MenuItem>
                      })
                    }
                  </Select>
                </FormControl>
              : ''
            }
       
      
          {/*<FormControl variant="outlined" style={{maxWidth:'184px', background:'#fff', marginLeft: '20px'}}>
                    <InputLabel htmlFor="outlined-age-simple">
                      Status
                    </InputLabel>
                    <Select
                      outlined='outlined'
                      autoWidth={true}
                      value={status}
                      onChange={this.handleUserChange}
                      input={<OutlinedInput name="status" id="outlined-age-simple" />}
                    >
                      <MenuItem value=''>All Jobs</MenuItem>
                      <MenuItem value='Assigned'>Assigned</MenuItem>
                      {
                        (this.state.userRole==='Denturist' || this.state.userRole==='Designer')
                        ? <div><MenuItem value="Design Job Accepted">Design Job Accepted</MenuItem>
                      <MenuItem value="Design Under Progress">Design Under Progress</MenuItem>
                      <MenuItem value="Design Completed">Design Completed</MenuItem></div>
                        : ''
                      }
                      {
                        (this.state.userRole==='Denturist' || this.state.userRole==='Mill')
                        ? <div>
                          <MenuItem value="Mill Job Accepted">Mill Job Accepted</MenuItem>
                          <MenuItem value="Milling Under Progress">Milling Under Progress</MenuItem>
                          <MenuItem value="Milling Completed">Milling Completed</MenuItem>
                        </div>
                        : ''
                      }
                      
                      <MenuItem value="Completed">Job Completed</MenuItem>
                    </Select>
                    </FormControl>*/}

          {
              (this.state.userRole==='Denturist')
              ? <FormControl variant="outlined" style={{maxWidth:'184px', background:'#fff', marginLeft: '20px'}}>
                <InputLabel htmlFor="outlined-age-simple">
                  Status
                </InputLabel>
                <Select
                  outlined='outlined'
                  autoWidth={true}
                  value={status}
                  onChange={this.handleUserChange}
                  input={<OutlinedInput name="status" id="outlined-age-simple" />}
                >
                  <MenuItem value=''>All Jobs</MenuItem>
                  <MenuItem value='Assigned'>Assigned</MenuItem>
                  <MenuItem value="Design Job Accepted">Design Job Accepted</MenuItem>
                  <MenuItem value="Design Under Progress">Design Under Progress</MenuItem>
                  <MenuItem value="Design Completed">Design Completed</MenuItem>
                  <MenuItem value="Mill Job Accepted">Mill Job Accepted</MenuItem>
                  <MenuItem value="Milling Under Progress">Milling Under Progress</MenuItem>
                  <MenuItem value="Milling Completed">Milling Completed</MenuItem>
                  <MenuItem value="Completed">Job Completed</MenuItem>
                </Select>
                </FormControl>
              : ""
          }

          {
              (this.state.userRole==='Designer')
              ? <FormControl variant="outlined" style={{maxWidth:'184px', background:'#fff', marginLeft: '20px'}}>
                <InputLabel htmlFor="outlined-age-simple">
                  Status
                </InputLabel>
                <Select
                  outlined='outlined'
                  autoWidth={true}
                  value={status}
                  onChange={this.handleUserChange}
                  input={<OutlinedInput name="status" id="outlined-age-simple" />}
                >
                  <MenuItem value=''>All Jobs</MenuItem>
                  <MenuItem value='Assigned'>Assigned</MenuItem>
                  <MenuItem value="Design Job Accepted">Design Job Accepted</MenuItem>
                  <MenuItem value="Design Under Progress">Design Under Progress</MenuItem>
                  <MenuItem value="Design Completed">Design Completed</MenuItem>
                  <MenuItem value="Completed">Job Completed</MenuItem>
                </Select>
                </FormControl>
              : ""
          }

          {
              (this.state.userRole==='Mill')
              ? <FormControl variant="outlined" style={{maxWidth:'184px', background:'#fff', marginLeft: '20px'}}>
                <InputLabel htmlFor="outlined-age-simple">
                  Status
                </InputLabel>
                <Select
                  outlined='outlined'
                  autoWidth={true}
                  value={status}
                  onChange={this.handleUserChange}
                  input={<OutlinedInput name="status" id="outlined-age-simple" />}
                >
                  <MenuItem value=''>All Jobs</MenuItem>
                  <MenuItem value='Assigned'>Assigned</MenuItem>
                  <MenuItem value="Mill Job Accepted">Mill Job Accepted</MenuItem>
                  <MenuItem value="Milling Under Progress">Milling Under Progress</MenuItem>
                  <MenuItem value="Milling Completed">Milling Completed</MenuItem>
                  <MenuItem value="Completed">Job Completed</MenuItem>
                </Select>
                </FormControl>
              : ""
          }
          
          <Paper className="tableContainer" spacing={2}>
          
            
            <Table className="table table-stripe new-Job">
              <TableHead>
                <TableRow>
                  {/*<TableCell>Id</TableCell>*/}
                  <TableCell align="right" >Title</TableCell>
                  <TableCell align="right">Date</TableCell>
                  
                  {
                     (this.state.userRole!=='Denturist')
                      ? <TableCell align="right">Assigned By</TableCell>
                      : <TableCell align="right">Assigned To</TableCell>
                  }
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right" width="20%">Action</TableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
                { 
                  this.state.loading 
                  ? <TableRow><TableCell colSpan="5" style={{ textAlign : 'center'}}><CircularProgress /></TableCell></TableRow>
                  : (docs.length)
                    ? docs.map(doc => (
                <TableRow key={doc.id}>
                  {/*<TableCell>{doc.id}</TableCell>*/}
                  <TableCell>{doc.Title}</TableCell>
                  <TableCell>{moment.unix(doc.Date.seconds).format('MMM DD, YYYY hh:mm A')}</TableCell>
                  {
                     (this.state.userRole!=='Denturist')
                      ? <TableCell>{doc.denturistName}</TableCell>
                      : <TableCell>{doc.millName}<br/>{doc.designerName}</TableCell>
                  }
                  
                  <TableCell>{doc.status}
                  
                  </TableCell>
                  <TableCell>
                    {  
                      this.state.isLoadedMsg.map((val, idx)=> {
                        if(val[doc.id]){
                          return (
                              <Link key={idx} to={ROUTES.CASE_DISPLAY + doc.id}>
                                <Tooltip title="You have unread messages" placement="top">
                                  <ChatOutlinedIcon />
                                </Tooltip>
                              </Link>
                          )
                        }
                      })
                    }
                    
                    <Link to={ROUTES.CASE_DISPLAY + doc.id}>
                      <Tooltip title="View Job" placement="top"><ListOutlined /></Tooltip>
                    </Link>
                    {
                      (this.state.userRole==='Denturist')
                      ? <span>
                          {
                            doc.status!=="Completed"
                            ? <Link to={ROUTES.CASE_EDIT + doc.id}><Tooltip title="Edit Job" placement="top"><EditOutlined/ ></Tooltip></Link>
                            : ''
                          }
                          <Link to={'/'} data-target="delete" data-title="Delete Job" onClick={this.handleClickOpen.bind(this, doc.id, 'delete')}><Tooltip title="Delete" placement="top"><DeleteOutline/ ></Tooltip></Link>
                          {
                            doc.status!=="Completed"
                            ? <Link to={'/'} data-target="mark_complete" data-title="Mark Completed" onClick={this.handleClickOpen.bind(this, doc.id, 'mark_completed')}><Tooltip title="Mark Completed" placement="top"><CheckIcon/ ></Tooltip></Link>
                            : ''
                          }

                          {
                            doc.payment_status==="Paid"
                            ? <span className="case_paid">Paid</span>
                            : ''
                          }

                        </span>
                      : ''
                    }
                    
                  </TableCell>
                </TableRow>
                ))
                : <TableRow><TableCell colSpan="5" style={{ textAlign : 'center'}}>No record found</TableCell></TableRow>



                }
              </TableBody>
            </Table>
          </Paper>
          </div>
      </div>
    );
  }
}

//export default withFirebase(Case);
export default compose(withFirebase)(Case);
