import moment from 'moment'
import React, { Component } from 'react'
import ReactLoading from 'react-loading'
import 'react-toastify/dist/ReactToastify.css'
import { withFirebase} from './../../Firebase'
import images from '../../../images/Images'
import './ChatBoard.css'
import { AppString } from '../../../components/Const'
//import firebase from 'firebase';
//import { AuthUserContext, withAuthorization } from './../../Session';

class ChatBoard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      isShowSticker: false,
      inputValue: '',
    }
    
    this.currentUserId = this.props.currentUserId;
    this.currentUserAvatar = this.props.currentUserAvatar
    this.currentUserNickname = this.props.currentUserNickname
    this.caseId = this.props.caseId
    this.listMessage = []
    this.currentPeerUser = this.props.currentPeerUser
    this.groupChatId = null
    this.removeListener = null
    this.currentPhotoFile = null
  }

  componentDidUpdate() {
    this.scrollToBottom()
  }

  componentDidMount() {
    // For first render, it's not go through componentWillReceiveProps
    this.getListHistory()
  }

  componentWillUnmount() {
    if (this.removeListener) {
      this.removeListener()
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.currentPeerUser) {
      this.currentPeerUser = newProps.currentPeerUser
      this.getListHistory()
    }
  }

  getListHistory = () => {
    if (this.removeListener) {
      this.removeListener()
    }
    this.listMessage.length = 0
    this.setState({ isLoading: true })
    if (
      this.hashString(this.currentUserId) <=
      this.hashString(this.currentPeerUser.userId)
    ) {
      this.groupChatId = `${this.currentUserId}-${this.currentPeerUser.userId}-${this.caseId}`
    } else {
      this.groupChatId = `${this.currentPeerUser.userId}-${this.currentUserId}-${this.caseId}`
    }

    //console.log(this.groupChatId);
    // Get history and listen new data added
    this.removeListener = this.props.firebase.msgDB().doc(this.groupChatId)
      .collection(this.groupChatId)
      .onSnapshot(
        snapshot => {
          snapshot.docChanges().forEach(change => {
            if (change.type === AppString.DOC_ADDED) {
              this.listMessage.push(change.doc.data())
            }
          })
          this.setState({ isLoading: false })
        },
        err => {
          this.props.showToast(0, err.toString())
        }
      )
  }

  openListSticker = () => {
    this.setState({ isShowSticker: !this.state.isShowSticker })
  }

  updateChatRoom(userId, timestamp){
    const last_visited = timestamp;
    //const userId = userId;
    const caseId = this.caseId;

    /*const chatRoomMessage = {
      last_visited: timestamp,
      userId: userId,
      caseId:this.caseId,
    }*/

    //console.log("this.currentUserId",userId)
    this.props.firebase.chatroomDB()
        .where("userId", "==", userId)
        .where("caseId", "==", caseId)
        .get({ source: 'server' })
        .then((querySnapshot) => {

          if(querySnapshot.docs.length){

              querySnapshot.forEach((doc) => {
                  console.log("zcx",doc.id)
                  if(this.currentUserId===userId){
                      if(doc.id){ //update chatboard document
                        const updateRef = this.props.firebase.chatroomDB().doc(doc.id);
                        updateRef.set({
                          caseId,
                          last_visited,
                          userId,
                        }).then((docRef) => {
                           
                        }).catch((error) => {  });
                      }else{ //create a new chatboard document
                        this.props.firebase.chatroomDB().add({
                            caseId,
                            last_visited,
                            userId,
                        }).then((docRef) => {
                              console.log(docRef);
                        })
                      }
                  }
              });
            }else{
              console.log('sdfsdf')
              this.props.firebase.chatroomDB().add({
                      caseId,
                      last_visited,
                      userId,
                  }).then((docRef) => {
                        console.log(docRef);
              })
            }


    });
  }

  onSendMessage = (content, type) => {
    if (this.state.isShowSticker && type === 2) {
      this.setState({ isShowSticker: false })
    }

    if (content.trim() === '') {
      return
    }

    const timestamp = moment()
      .valueOf()
      .toString()

    const itemMessage = {
      idFrom: this.currentUserId,
      idTo: this.currentPeerUser.userId,
      caseId:this.caseId,
      timestamp: timestamp,
      content: content.trim(),
      type: type
    }

    this.props.firebase.msgDB()
      .doc(this.groupChatId)
      .collection(this.groupChatId)
      .doc(timestamp)
      .set(itemMessage)
      .then(() => {
        this.setState({ inputValue: '' })
      })
      .catch(err => {
        this.props.showToast(0, err.toString())
      })

      //console.log(this.currentPeerUser)
      this.updateChatRoom(this.currentUserId,timestamp);


      const startdate = moment().subtract(10, "seconds").valueOf().toString();
      this.updateChatRoom(this.currentPeerUser.userId,startdate);
      
    /*const last_visited = timestamp;
    const userId = this.currentUserId;
    const caseId = this.caseId;

    const chatRoomMessage = {
      last_visited: timestamp,
      userId: this.currentUserId,
      caseId:this.caseId,
    }

    console.log("this.currentUserId",this.currentUserId)
    this.props.firebase.chatroomDB()
        .where("userId", "==", userId)
        .where("caseId", "==", caseId)
        .get({ source: 'server' })
        .then((querySnapshot) => {

          if(querySnapshot.docs.length){

              querySnapshot.forEach((doc) => {
                  console.log("zcx",doc.id)
                  if(doc.id){ //update chatboard document
                    const updateRef = this.props.firebase.chatroomDB().doc(doc.id);
                    updateRef.set({
                      caseId,
                      last_visited,
                      userId,
                    }).then((docRef) => {
                       
                    }).catch((error) => {  });
                  }else{ //create a new chatboard document
                    this.props.firebase.chatroomDB().add({
                        caseId,
                        last_visited,
                        userId,
                    }).then((docRef) => {
                          console.log(docRef);
                    })

                    
                  }
              });
            }else{
              console.log('sdfsdf')
                this.props.firebase.chatroomDB()
                    .doc(this.caseId+'-'+this.currentUserId)
                    .set(chatRoomMessage)
                    .then(() => {
                    })
                    .catch(err => {
                    })
            }


    });*/
  }

  onChoosePhoto = event => {
    if (event.target.files && event.target.files[0]) {
      this.setState({ isLoading: true })
      this.currentPhotoFile = event.target.files[0]
      // Check this file is an image?
      const prefixFiletype = event.target.files[0].type.toString()
      if (prefixFiletype.indexOf(AppString.PREFIX_IMAGE) === 0) {
        this.uploadPhoto()
      } else {
        this.setState({ isLoading: false })
        this.props.showToast(0, 'This file is not an image')
      }
    } else {
      this.setState({ isLoading: false })
    }
  }

  uploadPhoto = () => {
    if (this.currentPhotoFile) {
      const timestamp = moment()
        .valueOf()
        .toString()

      const uploadTask = this.props.firebase.msgStore()
        .child(timestamp)
        .put(this.currentPhotoFile)

      uploadTask.on(
        AppString.UPLOAD_CHANGED,
        null,
        err => {
          this.setState({ isLoading: false })
          this.props.showToast(0, err.message)
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            this.setState({ isLoading: false })
            this.onSendMessage(downloadURL, 1)
          })
        }
      )
    } else {
      this.setState({ isLoading: false })
      this.props.showToast(0, 'File is null')
    }
  }

  onKeyboardPress = event => {
    if (event.key === 'Enter') {
      this.onSendMessage(this.state.inputValue, 0)
    }
  }

  scrollToBottom = () => {
    if (this.messagesEnd) {
      this.messagesEnd.scrollIntoView({})
    }
  }

  render() {
    


    return (
      <div className="viewChatBoard">
        {/* Header */}
        {/*}
        <div className="headerChatBoard">
          <img
            className="viewAvatarItem"
            src={this.currentPeerUser.photoUrl}
            alt="icon avatar"
          />
          <span className="textHeaderChatBoard">
            {this.currentPeerUser.nickname}
          </span>
        </div>
    {*/}
        {/* List message */}
        <div className="viewListContentChat">
          {this.renderListMessage()}
          <div
            style={{ float: 'left', clear: 'both' }}
            ref={el => {
              this.messagesEnd = el
            }}
          />
        </div>

        {/* Stickers */}
        {this.state.isShowSticker ? this.renderStickers() : null}

        {/* View bottom */}
        <div className="viewBottom">
          <img
            className="icOpenGallery"
            src={images.ic_photo}
            alt="icon open gallery"
            onClick={() => this.refInput.click()}
          />
          <input
            ref={el => {
              this.refInput = el
            }}
            accept="image/*"
            className="viewInputGallery"
            type="file"
            onChange={this.onChoosePhoto}
          />

          {/*<img
                      className="icOpenSticker"
                      src={images.ic_sticker}
                      alt="icon open sticker"
                      onClick={this.openListSticker}
                    />*/}

          <input
            className="viewInput"
            placeholder="Type your message..."
            value={this.state.inputValue}
            onChange={event => {
              this.setState({ inputValue: event.target.value })
            }}
            onKeyPress={this.onKeyboardPress}
          />
          <img
            className="icSend"
            src={images.ic_send}
            alt="icon send"
            onClick={() => this.onSendMessage(this.state.inputValue, 0)}
          />
        </div>

        {/* Loading */}
        {this.state.isLoading ? (
          <div className="viewLoading">
            <ReactLoading
              type={'spin'}
              color={'#203152'}
              height={'7%'}
              width={'7%'}
              className="rloading"
            />
          </div>
        ) : null}
      </div>
    )
  }

  renderListMessage = () => {
    let img_r = "";
    if(this.currentPeerUser.photoUrl==="" || this.currentPeerUser.photoUrl===undefined){
        img_r = images.ic_avatar
      }else{
        img_r = this.currentPeerUser.photoUrl
      }

    if (this.listMessage.length > 0) {
      let viewListMessage = []
      this.listMessage.forEach((item, index) => {
        if (item.idFrom === this.currentUserId) {
          // Item right (my message)
          if (item.type === 0) {
            viewListMessage.push(
              <div className="viewItemRight" key={item.timestamp}>
                <span className="textContentItem">{item.content}</span>
              </div>
            )
          } else if (item.type === 1) {
            viewListMessage.push(
              <div className="viewItemRight2" key={item.timestamp}>
                <img
                  className="imgItemRight"
                  src={item.content}
                  alt="content message"
                />
              </div>
            )
          } else {
            viewListMessage.push(
              <div className="viewItemRight3" key={item.timestamp}>
                <img
                  className="imgItemRight"
                  src={this.getGifImage(item.content)}
                  alt="content message"
                />
              </div>
            )
          }
        } else {
          // Item left (peer message)
          if (item.type === 0) {
            viewListMessage.push(
              <div className="viewWrapItemLeft" key={item.timestamp}>
                <div className="viewWrapItemLeft3">
                  {this.isLastMessageLeft(index) ? (
                    <img
                      src={img_r}
                      alt="avatar"
                      className="peerAvatarLeft"
                    />
                  ) : (
                    <div className="viewPaddingLeft" />
                  )}
                  <div className="viewItemLeft">
                    <span className="textContentItem">{item.content}</span>
                  </div>
                </div>
                {this.isLastMessageLeft(index) ? (
                  <span className="textTimeLeft">
                    {moment(Number(item.timestamp)).format('ll')}
                  </span>
                ) : null}
              </div>
            )
          } else if (item.type === 1) {
            viewListMessage.push(
              <div className="viewWrapItemLeft2" key={item.timestamp}>
                <div className="viewWrapItemLeft3">
                  {this.isLastMessageLeft(index) ? (
                    <img
                      src={img_r}
                      alt="avatar"
                      className="peerAvatarLeft"
                    />
                  ) : (
                    <div className="viewPaddingLeft" />
                  )}
                  <div className="viewItemLeft2">
                    <img
                      className="imgItemLeft"
                      src={item.content}
                      alt="content message"
                    />
                  </div>
                </div>
                {this.isLastMessageLeft(index) ? (
                  <span className="textTimeLeft">
                    {moment(Number(item.timestamp)).format('ll')}
                  </span>
                ) : null}
              </div>
            )
          } else {
            viewListMessage.push(
              <div className="viewWrapItemLeft2" key={item.timestamp}>
                <div className="viewWrapItemLeft3">
                  {this.isLastMessageLeft(index) ? (
                    <img
                      src={img_r}
                      alt="avatar"
                      className="peerAvatarLeft"
                    />
                  ) : (
                    <div className="viewPaddingLeft" />
                  )}
                  <div className="viewItemLeft3" key={item.timestamp}>
                    <img
                      className="imgItemLeft"
                      src={this.getGifImage(item.content)}
                      alt="content message"
                    />
                  </div>
                </div>
                {this.isLastMessageLeft(index) ? (
                  <span className="textTimeLeft">
                    {moment(Number(item.timestamp)).format('ll')}
                  </span>
                ) : null}
              </div>
            )
          }
        }
      })
      return viewListMessage
    } else {
      return (
        <div className="viewWrapSayHi">
          <span className="textSayHi">Start a new Conversation</span>
          {/*<img
                      className="imgWaveHand"
                      src={images.ic_wave_hand}
                      alt="wave hand"
                    />*/}
        </div>
      )
    }
  }

  renderStickers = () => {
    return (
      <div className="viewStickers">
        <img
          className="imgSticker"
          src={images.mimi1}
          alt="sticker"
          onClick={() => this.onSendMessage('mimi1', 2)}
        />
        <img
          className="imgSticker"
          src={images.mimi2}
          alt="sticker"
          onClick={() => this.onSendMessage('mimi2', 2)}
        />
        <img
          className="imgSticker"
          src={images.mimi3}
          alt="sticker"
          onClick={() => this.onSendMessage('mimi3', 2)}
        />
        <img
          className="imgSticker"
          src={images.mimi4}
          alt="sticker"
          onClick={() => this.onSendMessage('mimi4', 2)}
        />
        <img
          className="imgSticker"
          src={images.mimi5}
          alt="sticker"
          onClick={() => this.onSendMessage('mimi5', 2)}
        />
        <img
          className="imgSticker"
          src={images.mimi6}
          alt="sticker"
          onClick={() => this.onSendMessage('mimi6', 2)}
        />
        <img
          className="imgSticker"
          src={images.mimi7}
          alt="sticker"
          onClick={() => this.onSendMessage('mimi7', 2)}
        />
        <img
          className="imgSticker"
          src={images.mimi8}
          alt="sticker"
          onClick={() => this.onSendMessage('mimi8', 2)}
        />
        <img
          className="imgSticker"
          src={images.mimi9}
          alt="sticker"
          onClick={() => this.onSendMessage('mimi9', 2)}
        />
      </div>
    )
  }

  hashString = str => {
    let hash = 0
    for (let i = 0; i < str.length; i++) {
      hash += Math.pow(str.charCodeAt(i) * 31, str.length - i)
      hash = hash & hash // Convert to 32bit integer
    }
    return hash
  }

  getGifImage = value => {
    switch (value) {
      case 'mimi1':
        return images.mimi1
      case 'mimi2':
        return images.mimi2
      case 'mimi3':
        return images.mimi3
      case 'mimi4':
        return images.mimi4
      case 'mimi5':
        return images.mimi5
      case 'mimi6':
        return images.mimi6
      case 'mimi7':
        return images.mimi7
      case 'mimi8':
        return images.mimi8
      case 'mimi9':
        return images.mimi9
      default:
        return null
    }
  }

  isLastMessageLeft(index) {
    if (
      (index + 1 < this.listMessage.length &&
        this.listMessage[index + 1].idFrom === this.currentUserId) ||
      index === this.listMessage.length - 1
    ) {
      return true
    } else {
      return false
    }
  }

  isLastMessageRight(index) {
    if (
      (index + 1 < this.listMessage.length &&
        this.listMessage[index + 1].idFrom !== this.currentUserId) ||
      index === this.listMessage.length - 1
    ) {
      return true
    } else {
      return false
    }
  }
}

export default  withFirebase(ChatBoard);
